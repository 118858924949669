import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { IEstacion } from '~/interfaces';

interface IState {
  estaciones: string[];
  servicio: string;
  tarjeta: string;
  limite: number;
  loadedConfigs: boolean;
  v: number;
}

interface IData {
  value: IState[keyof IState];
  _id: keyof IState;
  _rev?: string;
}

let Usuario: PouchDB.Database<IData>;

export const useUserStore = defineStore(
  'user',
  () => {
    const estacionesServicio = ref<Record<string, Set<string>>>({});
    const estaciones = computed({
      get: () => {
        const estacionesSet = estacionesServicio.value[servicio.value];
        return Array.from(estacionesSet?.size ? estacionesSet : []);
      },
      set: (estacion: IEstacion) => {
        const estacionesSet =
          estacionesServicio.value[servicio.value] || new Set<string>();
        estacionesSet.add(estacion.id);
        estacionesServicio.value[servicio.value] = estacionesSet;
      },
    });

    const servicio = ref('');
    const tarjeta = ref('');
    const limite = ref(0);
    const loadedConfigs = ref(false);
    const v = ref(1);

    const { $db } = useNuxtApp();

    function populateState(data: IData[]) {
      data.forEach((d: IData) => {
        if (d._id === 'estaciones') {
          estaciones.value = d.value as string[];
        } else if (d._id === 'limite') {
          limite.value = Number.parseInt(String(d.value), 10);
        } else if (d._id === 'servicio') {
          servicio.value = String(d.value);
        } else if (d._id === 'tarjeta') {
          tarjeta.value = String(d.value);
        }
      });
    }

    async function initDB() {
      if (!Usuario) {
        Usuario = $db.Usuario;
      }
      if (loadedConfigs.value || !Usuario) return;

      let usuarioPrefs = await Usuario.allDocs({ include_docs: true }).then(
        ({ rows }) => rows.map((row) => row.doc)
      );

      const findX = (prop = 'estaciones') =>
        usuarioPrefs.findIndex((est) => est?._id === prop) >= 0;

      const existenEstaciones = findX('estaciones');
      const existenTarjetas = findX('tarjeta');
      const existenLimite = findX('limite');
      const existenServicio = findX('servicio');

      await Promise.all(
        [
          !existenEstaciones && Usuario.put({ _id: 'estaciones', value: [] }),
          !existenTarjetas && Usuario.put({ _id: 'tarjeta', value: '' }),
          !existenLimite && Usuario.put({ _id: 'limite', value: 0 }),
          !existenServicio && Usuario.put({ _id: 'servicio', value: '' }),
        ].filter((d) => d)
      );

      usuarioPrefs = await Usuario.allDocs({ include_docs: true }).then(
        ({ rows }) => rows.map((row) => row.doc)
      );

      if (usuarioPrefs) {
        populateState(usuarioPrefs as IData[]);
      }
      loadedConfigs.value = true;
    }

    async function setPreferencia({
      key,
      value,
    }: {
      key: keyof IState;
      value: IState[keyof IState];
    }) {
      if (!key) throw new Error('Faltan datos');
      if (!['estaciones', 'servicio', 'tarjeta', 'limite'].includes(key))
        throw new Error('Preferencia no encontrada');

      if (key === 'estaciones') {
        estaciones.value = value as string[];
      } else if (key === 'limite') {
        limite.value = value as number;
      } else if (key === 'servicio') {
        servicio.value = value as string;
      } else if (key === 'tarjeta') {
        tarjeta.value = value as string;
      }

      return;
    }

    function saveEstacion(estacion: string, servicio = '6') {
      const remove = estaciones.value.includes(estacion);
      if (!remove) {
        if (!estacionesServicio.value.has(servicio)) {
          estacionesServicio.value.set(servicio, new Set<string>());
        }
        const estacionesSet = estacionesServicio.value.get(servicio);
        if (estacionesSet) {
          estacionesSet.add(estacion);
        }
      } else {
        if (estacionesServicio.value.has(servicio)) {
          const estacionesSet = estacionesServicio.value.get(servicio);
          if (estacionesSet) {
            estacionesSet.delete(estacion);
          }
        }
      }
      return estacionesServicio.value;
    }

    return {
      estaciones,
      estacionesServicio,
      servicio,
      tarjeta,
      limite,
      loadedConfigs,
      v,

      populateState,
      initDB,
      setPreferencia,
      saveEstacion,
    };
  },
  { persist: true }
);
